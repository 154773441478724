import moment from 'moment';
import 'moment/locale/fr';
import defaultAvatar from '../assets/img/default-avatar.png';
import defaultProvider from '../assets/img/default-provider.png';

const language = window.navigator.userLanguage || window.navigator.language;

export const capitalize = val =>
  val ? val.charAt(0).toUpperCase() + val.slice(1).toLowerCase() : null;

export const moneyFormatter = val => {
  let result = null;

  try {
    result = Intl.NumberFormat(language, {
      style: 'currency',
      currency: 'EUR'
    }).format(parseFloat(val));

    return result.replace(/NaN/, '-').replace(/\s/g, ' ');
  } catch (e) {
    return val.toString();
  }
};

export const dateFormatter = (date, format = 'llll', locate = 'fr') => {
  if (!date) return null;
  moment.locale(locate);
  return moment(date).format(format);
};

export const dateFormatterSmall = (
  date,
  format = 'DD/MM/YY',
  locate = 'fr'
) => {
  if (!date) return null;
  moment.locale(locate);
  return moment(date).format(format);
};

export const clientName = (client, defaultName = '') => {
  return client &&
    client.client &&
    (client.client.firstname || client.client.lastname)
    ? `${client.client.firstname ? client.client.firstname : ''} ${
        client.client.lastname ? client.client.lastname : ''
      }`
    : client && client.displayName
    ? client.displayName
    : defaultName;
};

export const clientAvatar = client => {
  return client && client.photoURL ? client.photoURL : defaultAvatar;
};

export const proName = (pro, defaultName = '') => {
  return pro && pro.pro && pro.pro.firstname && pro.pro.lastname
    ? `${pro.pro.firstname} ${pro.pro.lastname}`
    : pro && pro.displayName
    ? pro.displayName
    : defaultName;
};

export const proAvatar = pro => {
  return pro && pro.photoURL
    ? pro.photoURL
    : pro && pro.pro && pro.pro.logo
    ? pro.pro.logo
    : defaultAvatar;
};

export const supervisorName = (supervisor, defaultName = '') => {
  return supervisor &&
    supervisor.supervisor &&
    supervisor.supervisor.firstname &&
    supervisor.supervisor.lastname
    ? `${supervisor.supervisor.firstname} ${supervisor.supervisor.lastname}`
    : supervisor && supervisor.displayName
    ? supervisor.displayName
    : defaultName;
};

export const userName = (user, defaultName = '') => {
  if (user.roles.findIndex(r => r === 'admin') !== -1)
    return user ? user.displayName : defaultName;
  if (user.roles.findIndex(r => r === 'supervisor') !== -1)
    return supervisorName(user, defaultName);
  if (user.roles.findIndex(r => r === 'pro') !== -1)
    return proName(user, defaultName);
  if (user.roles.findIndex(r => r === 'seller') !== -1)
    return sellerName(user, defaultName);
  if (user.roles.findIndex(r => r === 'client') !== -1)
    return clientName(user, defaultName);
};

export const supervisorAvatar = supervisor => {
  return supervisor && supervisor.photoURL
    ? supervisor.photoURL
    : defaultAvatar;
};

export const sellerName = (seller, defaultName = '') => {
  return seller &&
    seller.seller &&
    seller.seller.firstname &&
    seller.seller.lastname
    ? `${seller.seller.firstname} ${seller.supervisor.lastname}`
    : seller && seller.displayName
    ? seller.displayName
    : defaultName;
};

export const sellerAvatar = seller => {
  return seller && seller.photoURL ? seller.photoURL : defaultAvatar;
};

export const providerName = (provider, defaultName = '') => {
  return provider && provider.name
    ? provider.name
    : provider && provider.contact && provider.contact.fullname
    ? provider.contact.fullname
    : defaultName;
};

export const providerAvatar = provider => {
  return provider && provider.logo ? provider.logo : defaultProvider;
};

export const quillConfig = {
  toolbar: [
    ['bold', 'italic', 'underline', 'strike'], // toggled buttons
    [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
    [{ align: [] }],
    ['clean'] // remove formatting button
    //['blockquote', 'code-block'],
    //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
    //[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
    //[{ 'direction': 'rtl' }],                         // text direction
    //[{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    //[{ 'font': [] }],
  ]
};

/**
 * MidpointRounding away from zero ('arithmetic' rounding)
 * Uses a half-epsilon for correction. (This offsets IEEE-754
 * half-to-even rounding that was applied at the edge cases).
 */

export const roundNumber = (num, precision = 2) => {
  // half epsilon to correct edge cases.
  let c = 0.5 * Number.EPSILON * num;
  //	var p = Math.pow(10, precision); //slow
  let p = 1;
  while (precision-- > 0) p *= 10;
  if (num < 0) p *= -1;
  return Math.round((num + c) * p) / p;
};
