import React, { Component } from 'react';
import {
  Redirect,
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import Login from './views/Auth/LoginPageNew';
import AppContext from './components/Context';
import indexRoutes from './routes/index.jsx';
import PasswordRecovery from './views/Auth/PasswordRecovery';
import {
  PWAInstall,
  Notifications,
  UpdateToken,
  CloudMessaging
} from './components';
import { compose } from 'redux';
import { connect } from 'react-redux';

class App extends Component {
  onError(err) {
    this.refs.notify.notificationAlert({
      type: 'danger',
      message: err,
      autoDismiss: 7
    });
  }

  deleteLoading() {
    let loading = document.getElementById('init-spinner');
    if (loading) loading.remove();
  }

  render() {
    let { user } = this.props;
    if (!isLoaded(user)) return null;

    this.deleteLoading();

    return (
      <AppContext.Provider
        value={{ user, authenticated: true, notify: this.onError }}
      >
        <PWAInstall message={''} accept={''} close={''} show={true} />
        <Router>
          <Switch>
            {!isEmpty(user)
              ? indexRoutes.map((prop, key) => {
                  return (
                    <Route
                      path={prop.path}
                      key={key}
                      component={prop.component}
                    />
                  );
                })
              : [
                  <Route
                    key={'login-component'}
                    path="/login"
                    component={Login}
                  />,
                  <Route
                    key={'reset-component'}
                    path="/auth/reset-password"
                    component={PasswordRecovery}
                  />,
                  <Redirect key={'redirect-login'} to={'/login'} />
                ]}
          </Switch>
        </Router>
        {!isEmpty(user) ? <Notifications /> : null}
        {!isEmpty(user) ? <CloudMessaging /> : null}
        {!isEmpty(user) ? <UpdateToken /> : null}
      </AppContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  return { user: state.fb.auth };
};

export default compose(connect(mapStateToProps))(App);
