import {
  baseUrl,
  createClient,
  get,
  createQueryString,
  getToken
} from './util';

const client = createClient('supervisors');

client.supervisorsContacts = params =>
  get(
    `${baseUrl}/supervisors/contacts/supervisors${createQueryString(params)}`
  );

client.prosContacts = params =>
  get(`${baseUrl}/supervisors/contacts/pros${createQueryString(params)}`);

client.sellersContacts = params =>
  get(`${baseUrl}/supervisors/contacts/sellers${createQueryString(params)}`);

client.clientsContacts = params =>
  get(`${baseUrl}/supervisors/contacts/clients${createQueryString(params)}`);

client.projects = params =>
  get(`${baseUrl}/supervisors/projects${createQueryString(params)}`);

client.visits = params =>
  get(`${baseUrl}/supervisors/visits${createQueryString(params)}`);

client.events = params =>
  get(`${baseUrl}/supervisors/events${createQueryString(params)}`);

client.notes = params =>
  get(`${baseUrl}/supervisors/notes${createQueryString(params)}`);

client.notesReport = () =>
  getToken().then(token =>
    Promise.resolve({
      url: `${baseUrl}/supervisors/reports/notes`,
      httpHeaders: {
        Authorization: 'Bearer ' + token,
        'x-api-role': 'admin'
      },
      withCredentials: false
    })
  );

export default client;
