import { baseUrl, get, createQueryString } from './util';
import clients from './clients';
import pros from './pros';
import projects from './projects';
import materials from './materials';
import operations from './operations';
import providers from './providers';
import supervisors from './supervisors';
import pieces from './pieces';
import surfaces from './surfaces';
import packages from './packages';
import admins from './admins';
import reports from './reports';
import users from './users';
import leroyMerlin from './leroymerlin';
import images from './images';
import partners from './partners';
import documents from './documents';
import sellers from './sellers';

export const Clients = clients;
export const Projects = projects;
export const Materials = materials;
export const Operations = operations;
export const Pros = pros;
export const Providers = providers;
export const Supervisors = supervisors;
export const Pieces = pieces;
export const Surfaces = surfaces;
export const Packages = packages;
export const Admins = admins;
export const Reports = reports;
export const Users = users;
export const LeroyMerlin = leroyMerlin;
export const Images = images;
export const Partners = partners;
export const Documents = documents;
export const Sellers = sellers;
export const UserData = () => get(`${baseUrl}/user`);

export default {
  Clients,
  Projects,
  Materials,
  Operations,
  Pros,
  Providers,
  Supervisors,
  Pieces,
  Surfaces,
  Packages,
  Admins,
  Reports,
  Users,
  LeroyMerlin,
  Images,
  Partners,
  Documents,
  Sellers,
  Security: () => get(`${baseUrl}/login`),
  Board: {
    Stats: params => get(`${baseUrl}/admin/board${createQueryString(params)}`),
    Notifications: params =>
      get(`${baseUrl}/admin/notifications${createQueryString(params)}`)
  },
  Finances: {
    Stats: params =>
      get(`${baseUrl}/admin/finances${createQueryString(params)}`)
  },
  UserData
};
